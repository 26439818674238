
export function Menu(props) {

    let model = props.model;

    function Stats() {

        let time = Math.floor((new Date() - model.startTime) /1000);

        return (
            <div className="stats">
                <h2>Menu</h2>
                <p>JD</p>
                <h3>Current Game:</h3>
                <p>Level: {model.level}</p>
                <p>Rows Cleared: {model.rowsCleared}</p>
                <p>Score: {model.score}</p>
                {model.startTime ? <p>Time Played: {time}s</p> : <p>Not Started</p>}
                <h3>Dev Stats</h3>
                <p>Initial Fall Rate: {model.config.initialFallRate}</p>
                <p>Current Fall Rate: {model.fallRate}</p>
                <p>Speed Up Threshold: {model.speedUpThreshold}</p>
                <p>Speed Up Rate: {model.speedUpRate}</p>
            </div>
        );
    }





    return (
        <div className="Menu">
            <Stats />          
        </div>
    );
}